// 最近12个月出入库
export function getChartsOptionBar12(data = []) {
  return {
    color: ["#37a2da", "#67e0e3", "#ffdb5c", "#ff9F7F"],
    legend: {},
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    title: {
      text: "最近12个月入|出库数据报表",
    },
    grid: {
      left: 0,
      right: 0,
      bottom: 0,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: data.map((item) => item.date),
      axisTick: {
        show: false,
      },
      axisLabel: {
        fontSize: 12,
        color: "#333",
      },
      axisLine: {
        lineStyle: {
          color: "#333",
        },
      },
    },
    yAxis: {
      type: "value",
      axisTick: {
        show: false,
      },
      axisLabel: {
        fontSize: 12,
        color: "#333",
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#333",
        },
      },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: "入库金额",
        data: data.map((item) => item.warehouse_total_price || 0),
        type: "bar",
        barWidth: 30,
      },
      {
        name: "入库数量",
        data: data.map((item) => item.warehouse_total_num || 0),
        type: "bar",
        barWidth: 30,
      },
      {
        name: "出库金额",
        data: data.map((item) => item.ex_warehouse_total_price || 0),
        type: "bar",
        barWidth: 30,
      },
      {
        name: "出库数量",
        data: data.map((item) => item.ex_warehouse_total_num || 0),
        type: "bar",
        barWidth: 30,
      },
    ],
  };
}

//获取经一年商品采购TOP10 以及领用
export function getChartsOptionRingChart({ title = "近一年商品采购TOP 10", data }) {
  return {
    // color:['#37a2da','#67e0e3','#ffdb5c','#ff9F7F'],
    tooltip: {
      trigger: "item",
    },
    title: {
      text: title,
    },
    grid: {
      left: 0,
      right: 0,
      bottom: 0,
      containLabel: true,
    },
    legend: {
      orient: "vertical",
      left: "5%",
      top: "center",
    },
    series: [
      {
        name: title,
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        data: data,
      },
    ],
  };
}
