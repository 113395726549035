<template>
  <div class="container-home">
    <!-- 申购入库出库 -->
    <ul class="flex-box nav-ul">
      <li
        @click="handleGoUrl(item)"
        :style="{ background: item.color }"
        class="nav-li"
        v-for="(item, index) in navList"
        :key="index"
      >
        {{ item.label }}
      </li>
    </ul>

    <!-- 统计最近30天的入库出库金额以及数量 -->
    <ul class="static-ul">
      <li class="static-li" v-for="(item, index) in staticList" :key="index">
        <div>{{ item.label }}</div>
        <div :style="{ color: item.color }" class="static-value">
          <span v-if="item.type == 'price'">￥{{ formData[item.value] }}元</span>
          <span v-else>{{ formData[item.value] }}</span>
        </div>
      </li>
    </ul>

    <!-- 最近12个月入出库数据报表 -->
    <div class="container-chart">
      <div id="bar-chart"></div>
    </div>

    <div class="container-chart chart-ring">
      <!-- 近一年商品采购TOP 10 -->
      <div class="commom-ring" id="ring-in-chart"></div>
      <!-- 近一年商品领用Top 10 -->
      <div class="commom-ring" id="ring-out-chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getChartsOptionBar12, getChartsOptionRingChart } from "./echartsOption/assetHomeOptions";
export default {
  name: "AssetHome",
  data() {
    return {
      formData: {
        warehouse_total_price: "32.0",
        ex_warehouse_total_price: "562.23",
        warehouse_total_num: "+36526",
        ex_warehouse_total_num: "-45811",
      },
      navList: [
        {
          //申购入库出库快捷入口
          label: "申购单添加",
          color: "#34BFA3",
          icon: "", //预留
          pathName: "PurchaseAdd",
        },
        {
          label: "入库单添加",
          color: "#36a3f7",
          icon: "", //预留
          pathName: "WarehousingAdd",
        },
        {
          label: "出库单添加",
          color: "#ffb822",
          icon: "", //预留
          pathName: "OutboundAdd",
        },
      ],
      staticList: [
        {
          type: "price",
          label: "最近30天入库金额",
          value: "warehouse_total_price", //formData.字段名
          color: "#34BFA3",
        },
        {
          type: "price",
          label: "最近30天出库金额",
          value: "ex_warehouse_total_price", //formData.字段名
          color: "#f4516c",
        },
        {
          type: "num",
          label: "最近30天入库数量",
          value: "warehouse_total_num", //formData.字段名
          color: "#716aca",
        },
        {
          type: "num",
          label: "最近30天出库数量",
          value: "ex_warehouse_total_num", //formData.字段名
          color: "#716aca",
        },
      ],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    //初始化数据
    initData() {
      this.$axios({
        url: "/admin/asset-goods/index-list",
        method: "GET",
      }).then((res) => {
        this.formData = res;

        //最近12个月入|出库数据报表 调用接口
        this.barChart = echarts.init(document.getElementById("bar-chart"));
        this.barChart.setOption(getChartsOptionBar12(res.month));

        //调用接口获取 近一年商品采购Top 10
        let purchaseData = res.warehouse.map((item) => {
          return {
            ...item,
            value: item.total_num,
            name: item.name,
          };
        });
        this.rinfInChart = echarts.init(document.getElementById("ring-in-chart"));
        this.rinfInChart.setOption(getChartsOptionRingChart({ title: "近一年商品采购TOP 10", data: purchaseData }));

        //近一年商品领用top10
        let useData = res.ex_warehouse.map((item) => {
          return {
            ...item,
            value: item.total_num,
            name: item.name,
          };
        });
        this.rinfOutChart = echarts.init(document.getElementById("ring-out-chart"));
        this.rinfOutChart.setOption(getChartsOptionRingChart({ title: "近一年商品领用TOP 10", data: useData }));
      });
    },
    //申购入库出库快捷入口
    handleGoUrl({ pathName }) {
      pathName && this.$router.push({ name: pathName });
    },
  },
};
</script>

<style lang="less" scoped>
.container-home {
  width: 100%;
  .container-chart {
    background: #fff;
    padding: 16px;
    box-sizing: border-box;
    margin-top: 24px;
    border-radius: 14px;
    #bar-chart {
      width: 100%;
      height: 400px;
    }
    &.chart-ring {
      display: flex;
      align-content: center;
      justify-content: space-between;
      height: 400px;
      margin-bottom: 24px;
      .commom-ring {
        width: 50%;
        border-right: 1px solid #f2f2f2;
        padding-right: 16px;
        &:last-child {
          padding-left: 16px;
          border-right: 0;
        }
      }
    }
  }
  .nav-ul {
    justify-content: space-between;
    cursor: pointer;
    .nav-li {
      width: 30%;
      height: 60px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 18px;
    }
  }

  .static-ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    flex-wrap: wrap;
    margin-top: 32px;
    padding: 16px 0;
    background: #fff;
    border-radius: 14px;
    .static-li {
      width: 50%;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:nth-of-type(n) {
        border-right: 1px solid #f2f2f2;
      }
      .static-value {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}
</style>
